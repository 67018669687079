<template>
  <div class="pageContol">
    <!-- 列表部分 -->
    <div class="framePage">
      <!-- 页面位置 -->
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据中心</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">登录信息统计</a>
        </span>
      </div>
      <div class="framePage-body">
        <!-- 操作区 -->
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 15px">
            <div title="学员信息" class="searchboxItem ci-full">
              <span class="itemLabel">学员信息:</span>
              <el-select
                v-model="searchData.userId"
                placeholder="请选择"
                no-data-text="没有数据"
                remote
                size="small"
                clearable
                :remote-method="superUserSelect"
                @visible-change="clearUserSearchModel"
              >
                <template>
                  <div class="select-header">
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        width: 100px;
                      "
                      >学员姓名</span
                    >
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        margin-left: 50px;
                        width: 140px;
                      "
                      >身份证号码</span
                    >
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        margin-left: 50px;
                        width: 100px;
                      "
                      >学员电话</span
                    >
                  </div>
                  <div class="select-header">
                    <el-input
                      v-model="searchData_User.seaUserName"
                      v-on:input="superUserSelect"
                      type="text"
                      size="small"
                      placeholder="学员姓名"
                      clearable
                      style="width: 100px"
                    />
                    <el-input
                      v-model="searchData_User.seaUserIdcard"
                      v-on:input="superUserSelect"
                      type="text"
                      size="small"
                      placeholder="身份证号码"
                      clearable
                      style="margin-left: 50px; width: 140px"
                    />
                    <el-input
                      v-model="searchData_User.seaUserMobile"
                      v-on:input="superUserSelect"
                      type="text"
                      size="small"
                      placeholder="学员电话"
                      clearable
                      style="margin-left: 50px; width: 100px"
                    />
                  </div>
                </template>
                <el-option
                  v-for="(item, index) in searchData_User.userList"
                  :key="index"
                  :label="item.userName"
                  :value="item.userId"
                >
                  <span style="width: 100px; font-size: 13px">{{
                    item.userName
                  }}</span>
                  <span
                    style="width: 140px; margin-left: 50px; font-size: 13px"
                    >{{ item.idcard }}</span
                  >
                  <span
                    style="width: 100px; margin-left: 50px; font-size: 13px"
                    >{{ item.mobile }}</span
                  >
                </el-option>
              </el-select>
            </div>
            <div title="班级信息" class="searchboxItem ci-full">
              <span class="itemLabel">班级信息:</span>
              <el-select
                v-model="searchData.projectId"
                placeholder="请选择"
                remote
                size="small"
                clearable
                :remote-method="superProjectSelect"
                @visible-change="clearProjectSearchModel"
              >
                <template>
                  <div class="select-header">
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        width: 150px;
                      "
                      >班级编号</span
                    >
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        margin-left: 50px;
                        width: 250px;
                      "
                      >班级名称</span
                    >
                  </div>
                  <div class="select-header">
                    <el-input
                      v-model="searchData_Project.seaProjectCode"
                      v-on:input="superProjectSelect"
                      type="text"
                      size="small"
                      placeholder="班级编号"
                      clearable
                      style="width: 150px"
                    />
                    <el-input
                      v-model="searchData_Project.seaProjectName"
                      v-on:input="superProjectSelect"
                      type="text"
                      size="small"
                      placeholder="请输入班级名称"
                      clearable
                      style="margin-left: 50px; width: 250px"
                    />
                  </div>
                </template>
                <el-option
                  v-for="item in searchData_Project.projectList"
                  :key="item.projectId"
                  :label="item.projectName"
                  :value="item.projectId"
                >
                  <span style="width: 150px; font-size: 13px">{{
                    item.projectCode
                  }}</span>
                  <span
                    style="width: 250px; margin-left: 50px; font-size: 13px"
                    >{{ item.projectName }}</span
                  >
                </el-option>
              </el-select>
            </div>
            <el-button
              class="bgc-bv"
              style="margin: 0 10px"
              round
              @click="getData()"
              >查询</el-button
            >
            <el-button
              class="bgc-bv"
              style="margin: 0 10px"
              round
              @click="handleExportList"
              >导出</el-button
            >
          </div>
        </div>
        <!-- 列表 -->
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                width="80px"
              />
              <el-table-column
                label="班级编码"
                align="left"
                prop="projectCode"
                show-overflow-tooltip
                width="120"
              />
              <el-table-column
                label="班级名称"
                align="left"
                prop="projectName"
                show-overflow-tooltip
                width="120"
              />
              <el-table-column
                label="姓名"
                align="left"
                show-overflow-tooltip
                prop="userName"
                width="80"
              />
              <el-table-column
                label="身份证"
                align="left"
                show-overflow-tooltip
                prop="idcard"
                width="160"
              />
              <el-table-column
                label="手机号"
                align="center"
                show-overflow-tooltip
                prop="mobile"
                width="140"
              />
              <el-table-column
                label="登录时间"
                align="center"
                show-overflow-tooltip
                prop="loginTime"
                width="140"
              />
              <el-table-column
                label="退出时间"
                align="center"
                show-overflow-tooltip
                prop="logoutTime"
                width="140"
              />
              <el-table-column
                label="登录ip"
                align="center"
                show-overflow-tooltip
                prop="loginIP"
                width="120"
              />
              <el-table-column
                label="ip属地"
                align="center"
                show-overflow-tooltip
                prop="loginIPCity"
                width="120"
              />
              <el-table-column
                label="学习内容"
                align="center"
                show-overflow-tooltip
                prop="learningContent"
              />
              <el-table-column
                label="有效学习时长"
                align="center"
                show-overflow-tooltip
                prop="effectiveLearningTime"
                width="100"
              />
              <el-table-column
                label="学时时长"
                align="center"
                show-overflow-tooltip
                prop="cumulativeLearningTime"
                width="100"
              />
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
export default {
  name: "statistics_signInformationStatistics",
  components: {
    Empty,
  },
  mixins: [List],
  doNotInit: true,
  data() {
    return {
      // 检索数据
      searchData: {
        userId: "", // 学员id
        projectId: "", // 班级Id
      },
      // 学员信息 - 组件查询内容
      searchData_User: {
        seaUserName: "", // 姓名
        seaUserIdcard: "", // 身份证号
        seaUserMobile: "", // 手机号
        userList: [], // 学员数据
      },
      // 班级信息 - 组件查询内容
      searchData_Project: {
        seaProjectCode: "", // 班级编码
        seaProjectName: "", // 班级名称
        projectList: [], // 班级列表
      },
    };
  },
  created() {
    this.superUserSelect();
    this.superProjectSelect();
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  methods: {
    // 获取 - 学员信息
    superUserSelect(e) {
      this.$post(
        "/biz/user/companyUserSelect",
        {
          pageNum: 1,
          pageSize: 5,
          userName: this.searchData_User.seaUserName,
          idcard: this.searchData_User.seaUserIdcard,
          mobile: this.searchData_User.seaUserMobile,
        },
        3000,
        false
      ).then((res) => {
        if (res.data.list.length > 0) {
          this.searchData_User.userList = res.data.list;
        } else {
          this.searchData_User.userList = [];
        }
      });
    },
    // 获取 - 学员信息 - 清空
    clearUserSearchModel(e) {
      if (e) {
        this.searchData_User.seaUserName = "";
        this.searchData_User.seaUserIdcard = "";
        this.searchData_User.seaUserMobile = "";
        this.superUserSelect();
      }
    },
    // 获取 - 班级信息
    superProjectSelect(e) {
      this.$post(
        "/biz/project/superSelectProject",
        {
          pageNum: 1,
          pageSize: 5,
          projectName: this.searchData_Project.seaProjectName,
          projectCode: this.searchData_Project.seaProjectCode,
        },
        3000,
        false
      ).then((res) => {
        if (res.data.list.length > 0) {
          this.searchData_Project.projectList = res.data.list;
        } else {
          this.searchData_Project.projectList = [];
        }
      });
    },
    // 获取 - 班级信息 - 清空
    clearProjectSearchModel(e) {
      if (e) {
        this.searchData_Project.seaProjectName = "";
        this.searchData_Project.seaProjectCode = "";
        this.superProjectSelect();
      }
    },
    // 获取 - 列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.searchData.userId) {
        params.userId = this.searchData.userId;
      } else {
        this.$message({
          message: "学员信息必须选择",
          type: "warning",
        });
        return false;
      }
      if (this.searchData.projectId) {
        params.projectId = this.searchData.projectId;
      } else {
        this.$message({
          message: "班级信息必须选择",
          type: "warning",
        });
        return false;
      }
      this.doFetch({
        url: "/gov/course/study/track/userSignInList",
        params,
        pageNum,
      });
    },
    // 导出
    handleExportList() {
      const params = {};
      if (this.searchData.userId) {
        params.userId = this.searchData.userId;
      }
      if (this.searchData.projectId) {
        params.projectId = this.searchData.projectId;
      } else {
        this.$message({
          message: "班级信息必须选择",
          type: "warning",
        });
        return false;
      }
      this.$post("/gov/course/study/track/jiading/userSignInListExport", params)
        .then((res) => {
          if (res.status == "0") {
            let list = [];
            list.push(res.data);
            for (let item of list) {
              console.log(item);
              if (!this.downloadItems.includes(item.taskId)) {
                this.$store.dispatch("pushDownloadItems", item.taskId);
              } else {
                this.$message.warning(
                  "[" + item.fileName + "]已经申请下载,请耐心等待"
                );
              }
            }
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((err) => {
          return;
        });
    },
  },
};
</script>
<style lang="less" scope>
.select-header {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
</style>
